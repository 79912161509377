<template>
  <div class="video-player"
    v-show="showPlayer"
    :class="[
      {'relative': relative},
      {'loop': loop},
      {'no-control': !showControl}]"
  >

    <div :style="{width: videoSize.w, height: videoSize.h}" class="video-container" :class="{'absolute-center': !relative}">

      <vue-plyr v-if="content.videoFile" class="player" ref="plyr">
        <video v-if="autoplay" @progress="onProgress" :src="content.videoFile" muted autoplay="true" playsinline crossorigin controls></video>
        <video v-else :src="content.videoFile" playsinline crossorigin controls></video>
      </vue-plyr>

      <vue-plyr v-if="content.videoLink && (content.videoLink.indexOf('youtube.com') > -1 || content.videoLink.indexOf('youtu.be') > -1)" class="player" ref="plyr">
        <div data-plyr-provider="youtube" data-target="video.player" :data-plyr-embed-id="getYoutubeId(content.videoLink)"></div>
      </vue-plyr>

    </div>
    <button v-if="skippable" @click="onSkip" type="button" class="skip">
      <CloseSvg />
      <small>{{ $root.translations[$language.current].skip }}</small>
    </button>
  </div>
</template>

<script>
import CloseSvg from '@/assets/svg/delete.svg'
import Viewport from '@monogrid/vue-lib/lib/mixins/Viewport'

import VuePlyr from 'vue-plyr'
import Vue from 'vue'
import 'plyr/src/sass/plyr.scss' // eslint-disable-line
import { gsap } from 'gsap'
// The second argument is optional and sets the default config values for every player.
Vue.use(VuePlyr, {
  plyr: {
    fullscreen: { enabled: true },
    hideControls: true,
    playsinline: true,
    autoplay: false,
    muted: false,
    loop: { active: false },
    controls: ['play-large', 'play', 'progress', 'mute']
  },
  emit: ['ready', 'ended', 'play', 'timeupdate', 'loadedmetadata']
})

const audioFadeTime = 0.5

export default {
  name: 'VideoPlayer',
  mixins: [Viewport],

  components: {
    CloseSvg
  },

  data () {
    return {
      wasPlaying: false,
      isFullscreen: false,
      videoSize: {
        w: '100%',
        h: '100%'
      }
    }
  },

  mounted () {
    document.addEventListener('visibilitychange', () => {
      if (!this.$refs.plyr) return
      if (document.visibilityState === 'visible') {
        if (this.wasPlaying) {
          this.playVideo(true)
        }
      } else {
        this.playVideo(false)
      }
    })

    this.isMuted = this.autoplay
    this.nativeWidth = 3840
    this.nativeHeight = 2160

    this.$refs.plyr.player.on('play', () => {
      this.wasPlaying = true
    })

    if (this.$refs.plyr) {
      this.$refs.plyr.player.on('ready', this.onVideoReady)
      this.$refs.plyr.player.on('timeupdate', this.onProgress)
      this.$refs.plyr.player.on('ended', this.onVideoFinished)
      this.$refs.plyr.player.on('loadedmetadata', (e) => {
        if (e.detail.plyr.media.videoWidth !== 0 && e.detail.plyr.media.videoHeight !== 0) {
          this.nativeWidth = e.detail.plyr.media.videoWidth
          this.nativeHeight = e.detail.plyr.media.videoHeight
          this.updateVideoSize()
        }
      })

      if (this.points) {
        this.pointsToReach = this.points
          .reduce((acc, p) => {
            acc.push({ pause: false, time: p.time - audioFadeTime, callback: () => this.fadeAudio('out') })
            acc.push(p)
            acc.push({ pause: false, time: p.time + 0.5, callback: () => this.fadeAudio('in') })
            return acc
          }, [])
        this.pointsReached = []
        this.$refs.plyr.player.on('timeupdate', this.onVideoUpdate)
      }
      this.updateVideoSize()
    }
  },
  methods: {
    onProgress () {
      if (!this.$refs.plyr) return
      if (this.$refs.plyr.player.currentTime >= (this.$refs.plyr.player.duration - 3)) {
        this.$emit('zoom')
      }
    },
    createAudioTimeline () {
      this.audioTl = gsap.timeline()
      this.audioTl.addLabel('out')
      this.audioTl.fromTo(this.$refs.plyr.$el, { volume: 0 }, { volume: 1, duration: audioFadeTime })
      this.audioTl.addLabel('in')
    },
    fadeAudio (state) {
      this.audioTl.tweenTo(state)
    },
    enableAudio () {
      this.isMuted = false
    },
    updateVideoSize () {
      if (this.cover) {
        const ratio = Math.max(this.viewPort.width / this.nativeWidth, this.viewPort.height / this.nativeHeight)
        this.videoSize.w = (this.nativeWidth * ratio) + 'px'
        this.videoSize.h = (this.nativeHeight * ratio) + 'px'
      }
    },
    onPointReached (i) {
      const { callback, pause = true } = this.pointsToReach[i]
      if (pause) {
        // this.player.currentTime = time
        this.player.pause()
      }
      this.pointsReached[i] = true
      callback()
    },
    onVideoUpdate () {
      if (!this.player) {
        return
      }
      this.pointsToReach.some((point, i) => {
        if (this.player.currentTime >= point.time && !this.pointsReached[i]) {
          this.onPointReached(i)
          return true
        }
        return false
      })
    },
    playVideo (play) {
      if (play) {
        if (this.$refs.plyr) {
          this.player = this.$refs.plyr.player
          this.player.muted = this.isMuted
          this.player.play()
        }
      } else {
        if (this.$refs.plyr) {
          this.player = this.$refs.plyr.player
          this.player.pause()
        }
      }
    },

    getYoutubeId (url) {
      const match = url.match(/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/) // eslint-disable-line
      return (match && match[7].length === 11) ? match[7] : false
    },
    onVideoReady () {
      this.$emit('onVideoReady')

      if (this.autoplay && this.$refs.plyr) {
        this.player = this.$refs.plyr.player
        this.player.muted = this.isMuted
        this.player.play()
      }

      if (this.isOverlay) {
        this.$events.emit('isLoadingEnd')
      }
      if (this.pointsToReach) {
        this.createAudioTimeline()
      }
    },
    onSkip () {
      this.$emit('skip')
      if (!this.player) return
      if (!this.pointsToReach || this.pointsToReach.length === this.pointsReached.length) {
        this.onVideoFinished()
      } else {
        this.onPointReached(this.pointsReached.length)
      }
    },
    onVideoFinished () {
      this.$emit('videoFinished')
      if (this.$refs.plyr) {
        this.$refs.plyr.player.on('timeupdate', () => {})
        this.player = this.$refs.plyr.player
        if (this.loop) {
          this.player.currentTime = 0
          this.player.play()
        } else {
          this.player.pause()
        }
      }
    }
  },

  watch: {
    showPlayer () {
      if (this.showPlayer) {
        this.playVideo(true)
      } else {
        this.playVideo(false)
      }
    },
    viewPort () {
      this.updateVideoSize()
    }
  },
  props: {
    relative: {
      type: Boolean
    },
    skippable: {
      type: Boolean
    },
    autoplay: {
      type: Boolean
    },
    cover: {
      type: Boolean
    },
    content: {
      type: Object
    },
    loop: {
      type: Boolean
    },
    showPlayer: {
      type: Boolean
    },
    points: {
      type: Array
    },
    showControl: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.video-player {
  position: absolute;
  top: 0;
  left: 0;
  // background-color: $c-white;
  width: 100%;
  height: 100%;

  &.relative {
    position: relative;
  }
}

.no-control,
.loop {
  pointer-events: none;

  ::v-deep .plyr__control--overlaid {
    display: none;
  }

  ::v-deep .plyr__controls {
    display: none;
  }
}

.video-container {
  width: 100%;
  height: 100%;
}

.skip {
  pointer-events: all;
  position: fixed;
  left: 50%;
  bottom: 100px;
  transform: translateX(-50%);
  outline: none;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border: 2px solid $c-white;

  html[lang='tw'] & {
    bottom: 200px;
  }

  &:hover::before {
    transform: translate(-50%, -50%) scale(1.5);
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: transparent radial-gradient(closest-side at 50% 50%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.153) 100%) 0% 0% no-repeat padding-box;
    transition: transform 0.3s;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 11px;
    height: 11px;
  }

  small {
    width: 100px;
    position: absolute;
    right: 50%;
    bottom: -30px;
    transform: translateX(50%);
    text-align: center;
    font: 12px/16px futura-pt;
    letter-spacing: 2.4px;
    text-transform: uppercase;
  }
}

svg {
  cursor: pointer;
  position: absolute;
  width: 40px;
  height: 40px;
}

::v-deep .plyr__control--overlaid {
  background: $c-white;
  padding: 30px;

  svg {
    fill: $c-gold;
  }

  &:hover {
    background: $c-gold !important;

    svg {
      fill: $c-white;
    }
  }
}

::v-deep .plyr__video-wrapper,
::v-deep .plyr--video {
  background-color: transparent;
  width: 100%;
  height: 100%;
}

::v-deep .plyr--full-ui input {
  color: $c-black !important;
}

::v-deep .plyr--video .plyr__control.plyr__tab-focus,

::v-deep .plyr--video .plyr__control:hover,

::v-deep .plyr--video .plyr__control[aria-expanded=true] {
  background: $c-black;
}

::v-deep .plyr__volume {
  min-width: auto;
  width: auto;
}
</style>
