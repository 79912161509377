<template>
  <div ref="scrubber" class="image-scrubber">
    <canvas
      ref="canvas"
      :width="`${width}px`"
      :height="`${height}px`"
    />
  </div>
</template>

<script>
import { RAF, ElasticNumber } from '@monogrid/js-utils'
import Viewport from '@monogrid/vue-lib/lib/mixins/Viewport'

export default {
  name: 'ImageScrubber',
  mixins: [Viewport],
  data () {
    return {
      scrubFrame: 0,
      frames: 0
    }
  },
  props: {
    imageDataArray: {
      type: Array,
      required: false,
      default () { return [] }
    },
    imageArray: {
      type: Array,
      required: false,
      default () { return [] }
    },
    alphaChannelImageArray: {
      type: Array,
      required: false,
      default () { return [] }
    },
    width: {
      required: false,
      type: Number,
      default: 100
    },
    height: {
      required: false,
      type: Number,
      default: 100
    },
    followMouse: {
      type: Boolean,
      required: false,
      default: false
    },
    followMouseInverted: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  async mounted () {
    this.playHead = new ElasticNumber(0)
    this.currentFrame = 0
    this.playHead.speed = 30
    this.time = new Date().getTime()
    this.ctx = this.$refs.canvas.getContext('2d')
    RAF.add(this.render)

    if (this.followMouse) {
      document.addEventListener('mousemove', this.sequenceFollowMouse)
      this.mouseX = 0
    }
  },
  destroyed () {
    RAF.remove(this.render)

    if (this.followMouse) {
      document.removeEventListener('mousemove', this.sequenceFollowMouse)
    }
  },
  watch: {
    imageDataArray: {
      handler () {
        this.forceRender = true
      },
      deep: true
    },
    imageArray: {
      handler () {
        this.forceRender = true
      },
      deep: true
    },
    alphaChannelImageArray: {
      handler () {
        this.forceRender = true
      },
      deep: true
    },
    width: {
      handler () {
        this.forceRender = true
      },
      deep: true
    },
    height: {
      handler () {
        this.forceRender = true
      },
      deep: true
    }
  },
  methods: {
    render () {
      const hasImageArray = (this.imageArray && this.imageArray.length > 0)
      const hasImageDataArray = (this.imageDataArray && this.imageDataArray.length > 0)
      this.frames = hasImageArray ? this.imageArray : hasImageDataArray ? this.imageDataArray : null
      if ((!hasImageArray && !hasImageDataArray) || !frames) return

      const prevFrame = this.currentFrame
      const now = new Date().getTime()
      const deltaTime = (now - this.time) / 1000

      if (this.followMouse) {
        this.currentFrame = this.mouseX < this.viewPort.width
          ? Math.round(this.frames.length * (this.mouseX / this.viewPort.width))
          : this.frames.length
        this.playHead.target = this.followMouseInverted ? this.currentFrame : -this.currentFrame
      } else if (this.currentFrame === this.frames.length - 4) {
        RAF.remove(this.render)
      } else {
        this.playHead.target++
      }

      this.playHead.update(deltaTime)
      // console.log(Math.abs(this.playHead.value - this.playHead.target))

      if (Math.abs(this.playHead.value - this.playHead.target) < 4 && !this.followMouse) {
        this.playHead.target = this.playHead.value // cut the slow easing to avoid jittering frames
      }

      this.currentFrame = this.absmod(Math.floor(this.playHead.value), this.frames.length)

      if (prevFrame !== this.currentFrame || this.forceRender) {
        // console.log('render', this.currentFrame)
        this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height)

        if (hasImageDataArray && this.frames[this.currentFrame]) {
          this.ctx.putImageData(this.frames[this.currentFrame], 0, 0)
        }

        if (hasImageArray) {
          if (this.alphaChannelImageArray && this.alphaChannelImageArray[this.currentFrame]) {
            // this.ctx.globalCompositeOperation = 'destination-in'
            this.ctx.drawImage(this.alphaChannelImageArray[this.currentFrame], 0, 0)
            const imageData = this.ctx.getImageData(0, 0, this.ctx.canvas.width, this.ctx.canvas.height)
            for (let i = 0, n = imageData.data.length; i < n; i += 4) {
              imageData.data[i + 3] = imageData.data[i]
            }
            this.ctx.putImageData(imageData, 0, 0)
            this.ctx.globalCompositeOperation = 'source-in'
          }

          if (this.frames[this.currentFrame]) {
            this.ctx.drawImage(this.frames[this.currentFrame], 0, 0)
          }
          this.ctx.globalCompositeOperation = 'source-over'
        }
      }
      this.forceRender = true

      this.time = now
    },
    absmod (n, m) {
      return ((n % m) + m) % m
    },
    sequenceFollowMouse (event) {
      this.mouseX = event.x
    }
  }
}
</script>

<style lang="scss" scoped>
.image-scrubber {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: visible;
}

canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  object-fit: contain;
}
</style>
