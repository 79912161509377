<template>
  <div class="image-sequence-container">
    <transition name="fade" mode="out-in">
      <div id="app-loading" v-if="loading">
        <svg class="spinner" viewBox="0 0 50 50">
          <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="2"></circle>
        </svg>
      </div>
      <ImageScrubber
        v-else
        :width="canvasWidth"
        :height="canvasHeight"
        :image-data-array="canvasImageDataArray"
        :image-array="canvasImageArray"
        :alpha-channel-image-array="canvasAlphaChannelImageArray"
        :follow-mouse="followMouse"
      />
    </transition>
  </div>
</template>

<script>
// shift first item, remove product main image

import ImageScrubber from '@/atoms/ImageScrubber.vue'
import filter from 'lodash/filter'
import JSZip from 'jszip'
import sortBy from 'lodash/sortBy'
import axios from 'axios'

export default {
  name: 'ProductImage360',

  components: {
    ImageScrubber
  },

  props: {
    content: {
      type: Object
    },
    followMouse: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  mounted () {
    this.loading = true
    this.loadZipOfJpg()
  },

  data () {
    return {
      isFullscreen: false,
      canvasImageDataArray: [],
      canvasImageArray: [],
      canvasAlphaChannelImageArray: [],
      canvasWidth: 100,
      canvasHeight: 100,
      loading: false
    }
  },

  methods: {
    imagePromise (file) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => {
          resolve(img)
        }
        img.onerror = (e) => {
          reject(new Error('Cannot natively decode image'))
        }
        img.src = file
      })
    },
    async loadZipOfJpg () {
      const response = await axios.get('/media/' + this.content.id, { responseType: 'arraybuffer' })
      // color
      const zip = new JSZip()
      await zip.loadAsync(response.data)

      let color = filter(sortBy(zip.filter(f => f.indexOf('transparency') === -1), ['name']), { dir: false })
      color = filter(color, (img) => {
        return img.name.indexOf('__MACOSX') === -1 && img.name.indexOf('DS_STORE') === -1 && img.name.indexOf('/.') === -1
      })

      const frames = new Array(color.length)

      const transparency = filter(sortBy(zip.filter(f => f.indexOf('transparency') !== -1), ['name']), { dir: false })
      const alphaChannel = new Array(transparency.length)

      for (let i = 0; i < color.length; i++) {
        let file = await color[i].async('base64')
        // console.log(file)
        this.imagePromise('data:image/jpeg;base64,' + file).then(img => {
          this.canvasWidth = img.width
          this.canvasHeight = img.height
          this.$set(frames, i, img)
          return null
        }).catch(e => { throw e })

        if (transparency[i]) {
          file = await transparency[i].async('base64')
          this.imagePromise('data:image/jpeg;base64,' + file).then(img => {
            this.$set(alphaChannel, i, img)
            return null
          }).catch(e => { throw e })
        }
      }
      this.canvasImageArray = frames
      this.canvasAlphaChannelImageArray = alphaChannel
      this.canvasImageDataArray = []
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.image-sequence-container {
  position: relative;
  height: 50vh;

  @include breakpoint ('sm-and-down') {
    height: 45vh;
  }
}
</style>
